import { createSlice } from '@reduxjs/toolkit';
// utils
//
// import { dispatch } from '../store';
import {
  addParticipantIntoCard,
  createNoteConversation,
  getCurrentConversation,
  sendNote,
} from '../../services/PlaygroundService';
// import { setCurrentCompany } from './company';
// import { addPointsToUser } from '../../services/RatingsService';

// ----------------------------------------------------------------------

const initialStateConversation = () => ({
  id: '',
  messages: [],
  participants: [],
  type: 'ONE_TO_ONE',
  unreadCount: 0,
});

export const initialState = {
  isLoading: false,
  error: null,
  id: null,
  usersList: [],
  clientsList: [],
  currentCard: {},
  conversation: { id: '', messages: [], participants: [], type: 'ONE_TO_ONE', unreadCount: 0 },
};

const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // SET CURRENT CARD
    setCurrentCard(state, action) {
      state.currentCard = action.payload;
    },

    // SET USERS LIST
    setUsersList(state, action) {
      state.usersList = action.payload;
    },

    // SET USERS LIST
    setClientsList(state, action) {
      state.clientsList = action.payload;
    },

    // GET CONVERSATION
    getConversationSuccess(state, action) {
      const conversation = action.payload;
      // console.log('getConversationSuccess conversation', conversation);
      const newConversation = {
        ...conversation,
        messages: conversation.messages || [],
      };
      state.conversation = newConversation;

      // if (conversation) {
      //   state.conversations.byId[conversation.id] = conversation;
      //   state.activeConversationId = conversation.id;
      //   if (!state.conversations.allIds.includes(conversation.id)) {
      //     state.conversations.allIds.push(conversation.id);
      //   }
      // } else {
      //   state.activeConversationId = null;
      // }
    },

    // ON SEND MESSAGE
    onSendMessage(state, action) {
      const { conversation, card } = action.payload;
      // console.log('onSendMessage conversation', conversation);
      const { messageId, message, contentType, attachments, createdAt, senderId } = conversation; // conversationId

      const newMessage = {
        ...(contentType === 'attachment' ? conversation.file : null),
        id: messageId,
        body: message,
        contentType,
        attachments,
        createdAt,
        senderId,
      };

      const messages = state.conversation.messages || [];
      const newMessages = [...messages, newMessage];
      // state.conversation.messages = newMessages;

      const newConversation = {
        ...conversation,
        messages: newMessages,
      };

      state.conversation = newConversation;

      state.currentCard.conversation = newConversation;
      console.log('newConversation xdddxd', newConversation);
      // setCard({ card, name: 'conversation', element: newConversation });
      // console.log('state.conversation', state.conversation.messages);
      // state.conversation.messages.push(newMessage); // state.conversations.byId[conversationId].messages.push(newMessage);
    },

    // SET NOTE CONVERSATION
    setNoteConversation(state, action) {
      const { conversation } = action.payload;
      state.conversation = conversation;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;
export const { setCurrentCard, setUsersList, setClientsList } = actions;

// ----------------------------------------------------------------------

// CONVERSATION

export function getConversation(companyId, conversationKey, senderId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/chat/conversation', {
      //   params: { conversationKey: 'sofia.funk' }, // params: { conversationKey },
      // });
      // console.log('conversations xd', response.data.conversation);
      // dispatch(slice.actions.getConversationSuccess(response.data.conversation));

      // let conversation = initialStateConversation(senderId); // .currentBoard;
      // console.log('1');
      let conversation = await getCurrentConversation(companyId, conversationKey);
      // console.log('c', conversation);
      if (!conversation) {
        // console.log('3');
        conversation = await createNoteConversation(companyId, initialStateConversation(senderId), conversationKey);
        // console.log('conversation creada', conversation);
      }
      //  else {
      //   conversation = await getCurrentConversation(conversationKey);
      //   // console.log('board', board);
      //   if (!conversation) {
      //     conversation = await createNoteConversation(companyId, initialStateConversation(senderId), conversationKey);
      //   }
      // }
      // console.log('conversation XD', conversation);
      dispatch(slice.actions.getConversationSuccess(conversation));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function onSendMessage({ conversation, card, companyId, profile }) {
  return async (dispatch) => {
    const { messageId, message, messages, contentType, attachments, createdAt, senderId } = conversation; // conversationId
    const newMessage = {
      ...(contentType === 'attachment' ? conversation.file : null),
      id: messageId,
      body: message,
      contentType,
      attachments,
      createdAt,
      senderId,
    };

    const newMessages = [...(messages || []), newMessage];

    await sendNote(companyId, card.id, newMessages);
    // comentado porque ya se usa el listening
    // dispatch(slice.actions.onSendMessage({ conversation, card }));

    const participants = conversation?.participants || [];

    const participantIds = participants.map((el) => el.id);
    const participantExists = participantIds.includes(profile.id);
    if (!participantExists) {
      const newParticipant = {
        address: profile.address || 'CR',
        avatar: profile.photoURL || '/static/user/user.png',
        email: profile.email || '',
        from: profile.phoneNumber || '',
        id: profile.id || '',
        uid: profile.id || '',
        lastActivity: new Date().toISOString(),
        name: `${profile.firstName} ${profile.lastName}`,
        phone: profile.phoneNumber || '',
        role: 'ux designer',
        status: 'online',
        username: `${profile.firstName || ''} ${profile.lastName || ''}`,
      };
      const newParticipants = [...participants, newParticipant];
      console.log('newParticipants', newParticipants);
      await addParticipantIntoCard(companyId, card.id, newParticipants);
    }
  };
}

export function setNoteConversation({ conversation }) {
  return async (dispatch) => {
    dispatch(slice.actions.setNoteConversation({ conversation }));
  };
}
